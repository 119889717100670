<template >
  <v-container fluid class="wid-100" style="background-color: rgba(241,211,107);height: auto;min-height: 100vh;">
    <template >
      <v-row no-gutters class="pa-0 ma-0" align="center">
        <v-col align-self="start">
          <v-icon size="30" color="white" @click="prePageBtnClicked">mdi-arrow-left</v-icon>
        </v-col>
        <v-col align-self="center" align="center" style="font-size: x-large;color: white;" >상품검색</v-col>
        <v-col align-self="end"></v-col>
      </v-row>

      <v-row class="pa-0 ma-0" >
        <v-col cols="auto">
          찾으시는 상품을 검색해주세요.
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-0" >
        <v-col cols="10">
          <v-text-field></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-icon size="30" color="white">mdi-magnify</v-icon>
        </v-col>
      </v-row>

      <v-row class="pa-0 ma-0" align="center">
        <v-col align-self="start">
          최근검색어
        </v-col>
        <v-col align-self="end" align="right">
          <v-icon color="white">mdi-delete</v-icon>
        </v-col>
      </v-row>
      <v-col cols="12"><v-divider></v-divider></v-col>

      <v-row class="pa-0 ma-0" align="center"
        v-for="(item,idx) in searchList"
        :key="`search_`+idx"
      >
        <v-col align-self="start">{{ item.keyword }}{{ idx }}</v-col>
        <v-col align-self="end" align="right"><v-icon color="white">mdi-close</v-icon></v-col>
      </v-row>

    </template>
  </v-container>    
</template>

<script>
export default {
  data() {
    return {
      searchList: [
        {keyword: '검색'},
        {keyword: '검색'},
        {keyword: '검색'},
        {keyword: '검색'},
        {keyword: '검색'},
        {keyword: '검색'},
        {keyword: '검색'},
        {keyword: '검색'},
        {keyword: '검색'},
        {keyword: '검색'},
        {keyword: '검색'},
        {keyword: '검색'},
        {keyword: '검색'},
        {keyword: '검색'},
        {keyword: '검색'},
        {keyword: '검색'},
      ]
    }
  },
  methods: {
    prePageBtnClicked() {
      const preUrl = this.$session.get('preUrl');
      if(preUrl) {
        this.$router.push(preUrl);
      } else {
        this.$router.push('/');
      }
    }
  }
}
</script>
<template>
    <v-dialog
      v-model="dialog"
      persistent
      overlay-color="yellow"
      max-width="200"
    >
    <div style="background-color: white;overflow-y: hidden;overflow-x: hidden;" >
        <template>
            <v-row align="center">
                <v-col cols="auto">
                    <label>코드를 입력해주세요.</label>
                </v-col>
                <v-col cols="auto">
                    <v-text-field 
                        single-line 
                        hide-details
                        model="codeNo"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="12">
                    <v-btn
                        color="primary"
                        dark
                        style="width: 100%;"
                        @click="confirmBtnClicked"
                    >확인</v-btn>
                </v-col>
            </v-row>
            
        </template>
    </div>
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5">
          Use Google's location service?
        </v-card-title>
        <v-card-text>Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Disagree
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card> -->
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: true,
            codeNo: '',
        }
    },
    created() {
        alert(1);
    },
    methods: {
        confirmBtnClicked() {
            
        }
    }
}
</script>
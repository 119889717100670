<template>
  <v-container fluid class="wid-100" style="background-color: rgba(241,211,107);">
    <template >
      <header3/>

      <v-row v-if="mobileYn" style="margin-top: 60px;background-color: rgba(241,211,107);">
        <v-col cols="12">
          <v-carousel 
            style="height: 50vh;" 
            cycle
            hide-delimiter-background
            interval="2000"
            progress-color=rgba(241,211,107)
          >
            <v-carousel-item
              v-for="(img ,idx) in imgList"
              :key="`mainImg_`+idx"
              :src="img.path"
              cover
            ></v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>

      <v-row v-if="mobileYn" class="pa-0 ma-0">
        <v-col cols="12" class="pa-0 ma-0">
          <h1>홀더세트</h1>
        </v-col>
        <v-col cols="12" class="pa-0 ma-0">
          <h2 style="color: gray;">투씨러스크 1BOX + 투씨홀더 1EA</h2>
        </v-col>
        <v-col cols="12"><v-divider></v-divider></v-col>

        <v-col cols="4" class="pa-0 ma-0">
          국내.해외배송
        </v-col>
        <v-col cols="8" class="pa-0 ma-0">
          국내배송
        </v-col>
        
        <v-col cols="4" class="pa-0 ma-0">
          배송방법
        </v-col>
        <v-col cols="8" class="pa-0 ma-0">
          택배
        </v-col>
        
        <v-col cols="4" class="pa-0 ma-0">
          배송비
        </v-col>
        <v-col cols="8" class="pa-0 ma-0">
          3,000원(20,000원 이상 무료 배송)
        </v-col>

        <v-col cols="4" class="pa-0 ma-0">
          브랜드명
        </v-col>
        <v-col cols="8" class="pa-0 ma-0">
          KIDKIDK
        </v-col>
      </v-row>

      <v-row class="wid-100">
        <v-col cols="12">
          <img src="https://m.angpapa.com/web/upload/NNEditor/20230922/EBACB4EBA38CEBB0B0EC86A1ver2.gif" style="width: 100%;height: auto;"/>
        </v-col>
        <v-col cols="12"><v-divider></v-divider></v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-tabs
            v-model="tab"
            background-color="transparent"
            color="basil"
            grow
          >
            <v-tab
              v-for="item in items"
              :key="item"
            >
              {{ item }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-card
                color="basil"
                flat
              >
                1
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card
                color="basil"
                flat
              >
                2
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card
                color="basil"
                flat
              >
                3
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card
                color="basil"
                flat
              >
                4
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>



    </template>
  </v-container>

</template>


<script>
import header3 from '@/components/header/header3'
export default {
  components: {
    header3,
  },
  data() {
    return {
      imgList : [
        {path: './img/main/1.jpg', color: '남색'},
        {path: './img/main/2.jpg', color: '무지개'},
        {path: './img/main/3.jpg', color: '주황'},
        {path: './img/main/4.jpg', color: '남색'},
        {path: './img/main/5.jpg', color: '남색'},
      ],
      mobileYn: false,
      tab: null,
      items: [
        '제품상세', '구매안내', '리뷰', 'Q&A',
      ],
    }
  },
  created() {
    if(window.innerWidth < 767 && window.innerWidth > 350) {
      this.mobileYn = true;
    }
  },
  methods: {

  },
}
</script>
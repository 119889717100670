
export default{
    install(Vue){

        //영어랑 숫자만되도록 정규식
        Vue.prototype.$engNum = function(data){
            var regExp = /^[a-z0-9]*$/;
            if(regExp.test(data)){
                //통과
                return true;
            }else{
                //올바르지 않음
                return false;
            }
        }

    }
}
<template>
  <v-app>
    <v-main>
      <!-- <v-container style="background-color: red;"> -->
        <router-view/>
      <!-- </v-container> -->
    </v-main>
  </v-app>
</template>
<script>
export default {
  name: 'App',
  data(){
    return{
      drawer: false,
    }
  },
}
</script>
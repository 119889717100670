<template>
  <v-container fluid class="wid-100">
    <template>
      <v-row style="width: 100%;">
        <v-col cols="6">
          <!-- <input type="file" id="upload-image" hidden multiple @change="fileChanged"/> -->
          <v-file-input id="upload-image" style="display: none;" multiple @change="fileChanged" v-model="fileList" ref="imgUpload"></v-file-input>
            <v-btn style="width: 100%;" @click="imgUploadClicked">
              <label>사진 업로드</label>
            </v-btn>
        </v-col>
        <v-col cols="6">
            <v-btn style="width: 100%;">
              <label>편집</label>
            </v-btn>
        </v-col>
        <!-- <v-col cols="6">
          <v-btn style="width: 100%;">사진 업로드</v-btn>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="4" v-for="(item, idx) in imgList" :key="`img_`+idx">
          <v-card @click="imgClicked(item)">
            <v-img :src="item.file_path" width="100px" height="100px"></v-img>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      fileList: null,
      imgList: [],
    }
  },
  created() {
    
    this.$http.get("/admin/sub/selectTbImg").then((res) => {
      console.log(res.data.imgList);
      this.imgList = res.data.imgList;
      
    }).catch(
      function (error) {
        console.log(error);
      }
    );
  },
  methods: {
    fileChanged() {
      console.log(this.fileList);

      const params = new FormData();
      
      this.fileList.forEach((e) => {
        params.append("fileList",e);
      })

      this.$http.post("/admin/sub/imgUpload", params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        if(res.data.code == 0) {
          alert('업로드 완료');
          this.$router.go(0);
        }
        
      }).catch(
        function (error) {
          console.log(error);
        }
      );
    },
    imgUploadClicked() {
      this.$refs.imgUpload.$el.querySelector('input').click();
    },
    imgClicked(item) {
      window.open(item.file_path);
    },//imgClicked end
  }
}
</script>

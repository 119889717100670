<template>
  <v-container fluid class="wid-100" style="background-color: rgba(241,211,107);height: auto;min-height: 100vh;">
    <template>
      <header3/>
      
      <v-row style="margin-top: 10vh;">
        <v-col align-self="start">
          <v-icon color="rgba(145,78,34)" size="30" @click="backBtnClicked">mdi-arrow-left</v-icon>
        </v-col>
        <v-col align-self="center" align="center">
          <h2 style="color: rgba(145,78,34)">장바구니</h2>
        </v-col>
        <v-col align-self="end"></v-col>
      </v-row>
      <v-col cols="12"><v-divider></v-divider></v-col>

      <v-row dense >
        <v-col cols="10">
          경기 김포시 사우로 9번길 20-6 중앙캐슬 101동 403호
        </v-col>
        <v-col cols="2" >
          <v-btn color="rgba(145,78,34)" class="white--text">변경</v-btn>
        </v-col>
      </v-row>
      <v-col cols="12"><v-divider></v-divider></v-col>
        
      <v-row dense align="center">
        <v-col cols="9">
          <v-checkbox 
            label="전체선택" 
            class="ma-0 pa-0" 
            hide-details="" 
            color="rgba(145,78,34)"
            @click="allSelClicked"
            v-model="allSel"
          >
          </v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-btn color="rgba(145,78,34)" class="white--text">선택삭제</v-btn>
        </v-col>
      </v-row>
      <v-col cols="12"><v-divider></v-divider></v-col>

      <v-card
        style="width: 100%;height: auto;background-color: white"
        tile
        elevation="5"
        v-for="(item, idx) in cartList"
        :key="`cart_`+idx"
        class="mb-4"
      >
        <v-row dense style="color: rgba(145,78,34);" align="center">
          <v-col cols="auto">
            <v-checkbox
              class="ma-0 pa-0" 
              hide-details
              color="rgba(145,78,34)"
              v-model="item.selYn"
            >
            <template v-slot:label>
              <div style="color: rgba(145,78,34)">
                {{item.nm}}
              </div>
            </template>
            </v-checkbox>
          </v-col>
          <v-col align="right">
            <v-btn small color="rgba(145,78,34)" class="white--text mr-2">X</v-btn>
          </v-col>
        </v-row>

        <v-row dense align="center" class="pa-2">
          <v-col cols="4">
            <v-avatar
              size="100"
              tile
            >
              <v-img :src="item.url"></v-img>
            </v-avatar>
          </v-col>
          <v-col cols="5">
            <v-row>색상 : </v-row>
            <v-row>설명 : </v-row>
          </v-col>
          <v-col cols="3" >
            <v-row class="mb-2">
              <v-col cols="12" class="pa-0">
                <h4>{{item.price.toLocaleString('ko-KR')}}</h4>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="auto" class="ma-0 pa-0">
                <v-btn @click="minorClicked(item)" height="35px" style="min-width: 0px;color: white;width: 10px;" color="rgba(145,78,34)"><h3>-</h3></v-btn>
              </v-col>
              <v-col cols="auto" class="ma-0 pa-0">
                <v-text-field 
                  hide-details 
                  dense  
                  outlined 
                  v-model="item.qty" 
                  type="number" 
                  style="min-width: 0px;width: 40px;min-height: 0px;"
                  filled
                  class="pa-0"
                >
                </v-text-field>
              </v-col>
              <v-col cols="auto" class="ma-0 pa-0">
                <v-btn @click="plusClicked(item)" height="35px" style="min-width: 0px;color: white;width: 10px;" color="rgba(145,78,34)"><h3>+</h3></v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
      <v-col cols="12"><v-divider></v-divider></v-col>
      
    </template>
  </v-container>
</template>

<script>
import header3 from '@/components/header/header3'

export default {
  components: {
    header3,
  },
  data() {
    return {
      qty: 1,
      allSel: false,
      cartList: [
        {selYn: false, qty: 1, price: 12000, url: 'https://product-image.kurly.com/cdn-cgi/image/fit=crop,width=360,height=468,quality=85/product/image/96ccd692-bd20-4cdc-b843-1e6d8110f8fb.jpg', nm: '소율스 반팔' },
        {selYn: false, qty: 1, price: 12900, url: 'https://3p-image.kurly.com/cdn-cgi/image/fit=crop,width=360,height=468,quality=85/files/578d9838-3eef-418d-aeb4-a56ebd7dbed2/bb5fb22b-1173-4572-bc85-c0a7b7b5de1b.jpg', nm: '소율스 긴팔' },
        {selYn: false, qty: 1, price: 15000, url: 'https://3p-image.kurly.com/cdn-cgi/image/fit=crop,width=360,height=468,quality=85/files/20231013/2ca8db68-d277-485b-a57f-88baa5fa47af.jpg', nm: '검은 우산' },
      ]
    }
  },
  methods: {
    qtyChanged(event) {
      console.log(event);
      if(!event || event == 0) {
        this.qty = 1;
      }
    },
    minorClicked(item) {
      if(item.qty != 1) {
        item.qty--;
      }
    },
    plusClicked(item) {
      item.qty++;
    },
    backBtnClicked() {
      const preUrl = this.$session.get('preUrl');
      if(preUrl) {
        this.$router.push(preUrl);
      } else {
        this.$router.push('/');
      }
    },//backBtnClicked end
    allSelClicked() {
      this.cartList.forEach((e) => {
        if(this.allSel) {
          e.selYn = true;
        } else {
          e.selYn = false;
        }
        
      })
    },//allSelClicked end
  }
}
</script>
<template>
  <v-container fluid class="wid-100" style="background-color: rgba(255,204,51); height: 100%;">
    <template >
      <v-row justify="center" align="center" >
        <v-col 
          cols="auto"
          class="pb-0 mb-0"
        >
          <img src="@/assets/logo/noname_logo.png" width="100px" justify="center" />
        </v-col>
        <v-col cols="12" class="text-center">
          KIDKIDK 스마트 스토어 쿠폰 발급
        </v-col>
        <v-col cols="12" >
          1. 스마트스토어 알림받기 누르기<br>(알림받기를 누르신 분에 한해 쿠폰발급이 가능합니다.)
        </v-col>
        <v-col cols="12" >
          2. 네이버 아이디 입력창에 아이디를 입력하고 쿠폰 등록 버튼 클릭<br>(중복 등록 불가)
        </v-col>
        
        
      </v-row>
      <v-row justify="center" align="center">
        <div style="border: 1px solid black; width: 80%;" class="pa-2" >
          <v-col cols="12" class="pa-0 ma-0">
            <v-text-field
              v-model="naverId"
              placeholder="네이버 아이디"
              class="text-center"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0 mb-2">
            <v-text-field
              v-model="naverName"
              placeholder="이름"
              class="text-center"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn color="rgba(145,78,34)" class="white--text text-center" @click="btnClicked" style="width: 100%;">쿠폰 등록</v-btn>
            <v-btn color="rgba(3,193,8)" class="white--text text-center mt-2" @click="goSmartStore" style="width: 100%;">스마트 스토어 이동</v-btn>
            <v-btn color="rgba(145,78,34)" class="white--text text-center mt-2" @click="kidkidkGo" style="width: 100%;">키드키득 홈페이지 이동</v-btn>
          </v-col>
        </div>
      </v-row>
    </template>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      naverId: '',
      naverName: '',
    }
  },
  methods: {
    btnClicked() {
      if(!this.naverId) {
        alert('네이버 아이디를 입력해주세요.');
        return;
      } else if(!this.naverName) {
        alert('이름을 입력해주세요.');
        return;
      }
      const param = new FormData();
      param.append('naverid', this.naverId);
      param.append('navername', this.naverName);

      this.$http.post("/admin/sub/insertNaverId", param).then((res) => {
        console.log(res.data);
        if(res.data.code == 0) {
          alert('등록이 완료되었습니다.');
          this.naverId = '';
          this.naverName = '';
          window.location.href = "https://kidkidk.com";
        } else {
          alert('이미 등록된 아이디입니다.');
          return;
        }
        
      }).catch(
        function (error) {
          console.log(error);
        }
      );
    },//btnClicked end
    kidkidkGo() {
      window.location.href = 'https://kidkidk.com';
    },
    goSmartStore() {
      window.location.href = 'https://smartstore.naver.com/kidkidk-market';
    }
  }
}
</script>

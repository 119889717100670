<template>
  <div>
    <!-- 모바일 -->
    <v-row style="position: fixed;width: 100%;z-index: 9000;margin-top: 27px;" class="headerRow" v-if="mobileYn">
      <v-col 
        cols="2"
        xs="2"
        class="text-center"
      >
        <v-icon class="ml-2 white--text" size="45" @click.stop="drawer = !drawer" @click="menuClicked">mdi-menu</v-icon>
      </v-col>
      <v-col 
        cols="8" 
        xs="2"
        class="text-center black--text" 
        style="font-size: xx-large;"
      >
      </v-col>
      <v-col 
        cols="2" 
        xs="2"
        class="text-center"
      >
        <v-icon class="mr-2 white--text" size="45" @click="searchBtnClick">mdi-magnify</v-icon>
      </v-col>
    </v-row>
    <!-- 웹 -->
    <v-row style="position: fixed;width: 100%;z-index: 9000;margin-top: 12px;" v-if="!mobileYn">
      <v-col cols="12">
        <v-img src="@/img/main/header2.png"></v-img>
      </v-col>
      <!-- <v-col 
        cols="2"
        class="text-center"
      >
        <v-icon class="ml-2 white--text" size="45" @click.stop="drawer = !drawer" @click="menuClicked">mdi-menu</v-icon>
      </v-col>
      <v-col 
        cols="8"
        class="text-center black--text" 
        style="font-size: xx-large;"
      >
      </v-col>
      <v-col 
        cols="2" 
        xs="2"
        class="text-center"
      >
        <v-icon class="mr-2 white--text" size="45" @click="searchBtnClick">mdi-magnify</v-icon>
      </v-col> -->
    </v-row>

    <v-navigation-drawer
      v-model="drawer"
      top
      temporary
      absolute
      :style="`z-index: 9999;width: 80%;background-color: rgba(000,051,102);margin-top:`+pageYOffset+`px;`"
      height="100vh"
    >
    <!-- hide-overlay -->
        <v-container >
          <v-row class="" style="color: rgba(196,196,196);white;height: 6vh;">
            <v-col cols="3" justify="center">
              <h4 @click="loginBtnClicked">로그인</h4>
            </v-col>
            <v-col cols="7" justify="center" @click="joinClicked">
              <h4>회원가입</h4>
            </v-col>
            <v-col cols="2">
              <v-icon class="white--text"  @click="drawer = false">mdi-close</v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col 
              cols="3"
              style="border: 1px solid black;height: 73px;"
              class="wid-100p d-flex justify-center align-center" 
            > 
            </v-col>
            <v-col 
              cols="3"
              style="border: 1px solid black;height: 73px;"
              class="wid-100p d-flex justify-center align-center" 
            >
            </v-col>
            <v-col 
              cols="3"
              style="border: 1px solid black;height: 73px;"
              class="wid-100p d-flex justify-center align-center" 
            > 
            </v-col>
            <v-col 
              cols="3"
              style="border: 1px solid black;height: 73px;"
              class="wid-100p d-flex justify-center align-center" 
            > 
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" >
              <v-card
                class="mx-auto"
                width="300"
                style="background-color: rgba(196,196,196);"
                
              >
                <v-list >
                  <v-list-group>
                    <template v-slot:activator>
                      <v-list-item-title>소율이네</v-list-item-title>
                    </template>

                    <v-list-group
                      :value="true"
                      no-action
                      sub-group
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>Admin</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item
                        v-for="([title], i) in admins"
                        :key="i"
                        link
                      >
                        <v-list-item-title >{{ title }}</v-list-item-title>
                      </v-list-item>
                    </v-list-group>

                    <v-list-group
                      no-action
                      sub-group
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>Actions</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item
                        v-for="([title], i) in cruds"
                        :key="i"
                        link
                      >
                        <v-list-item-title>{{ title }}</v-list-item-title>
                      </v-list-item>
                    </v-list-group>
                  </v-list-group>

                  <v-list-group>
                    <template v-slot:activator>
                      <v-list-item-title>도경이네</v-list-item-title>
                    </template>

                    <v-list-group
                      :value="true"
                      no-action
                      sub-group
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>Admin</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item
                        v-for="([title], i) in admins"
                        :key="i"
                        link
                      >
                        <v-list-item-title>{{ title }}</v-list-item-title>
                      </v-list-item>
                    </v-list-group>

                    <v-list-group
                      no-action
                      sub-group
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>Actions</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item
                        v-for="([title], i) in cruds"
                        :key="i"
                        link
                      >
                        <v-list-item-title>{{ title }}</v-list-item-title>
                      </v-list-item>
                    </v-list-group>
                  </v-list-group>

                  <v-list-group>
                    <template v-slot:activator>
                      <v-list-item-title>이준이네</v-list-item-title>
                    </template>

                    <v-list-group
                      :value="true"
                      no-action
                      sub-group
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>Admin</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item
                        v-for="([title], i) in admins"
                        :key="i"
                        link
                      >
                        <v-list-item-title>{{ title }}</v-list-item-title>
                      </v-list-item>
                    </v-list-group>

                    <v-list-group
                      no-action
                      sub-group
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>Actions</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item
                        v-for="([title], i) in cruds"
                        :key="i"
                        link
                      >
                        <v-list-item-title >{{ title }}</v-list-item-title>
                      </v-list-item>
                    </v-list-group>
                  </v-list-group>

                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
          
        </v-navigation-drawer>
  </div>
  
</template>

<script>
export default {
  data() {
    return {
      drawer: false,
      admins: [
        ['Management', 'mdi-account-multiple-outline'],
        ['Settings', 'mdi-cog-outline'],
      ],
      cruds: [
        ['Create', 'mdi-plus-outline'],
        ['Read', 'mdi-file-outline'],
        ['Update', 'mdi-update'],
        ['Delete', 'mdi-delete'],
      ],
      pageYOffset: 0,
      mobileYn: false,
    }
  },
  created() {
    if(window.innerWidth < 767 && window.innerWidth > 350) {
      this.mobileYn = true;
    }
  },  
  methods: {
    menuClicked() {
      // alert(window.pageYOffset);
      this.pageYOffset = window.pageYOffset;
    },//menuClicked end
    joinClicked() {
      this.$router.push('/join');
    },//joinClicked end 
    loginBtnClicked() {
      this.$session.set('preUrl',this.$route.path);
      this.$router.push('/login')
    },//loginBtnClicked end
    searchBtnClick() {
      this.$session.set('preUrl',this.$route.path);
      this.$router.push('/search');
    },//searchBtnClick end
  }
}
</script>

<style scoped>
.headerRow::v-deep {
  background: url("@/img/main/header2.png");
  background-size: cover;
  padding-bottom: 3px;
  border: 1px solid black;
}

.web-headerRow::v-deep {
  background: url("@/img/main/header2.png");
  background-size: cover;
  padding-bottom: 3px;
  border: 1px solid black;
}
</style>
<template>
    <v-container style="background-color: rgba(241,211,107);" fluid class="wid-100">
      <template>
        <!-- 모바일 -->
        <!-- <v-row style="font-weight: bold;font-size: medium; color: black;position: fixed;width: 100%;z-index: 9000;" justify="center" v-if="mobileYn" class="header1">
          <v-col 
            cols="auto"
            class="pa-2"
            @click="tests"
          >
          로그인
          </v-col>
          <v-col 
            cols="auto"
            class="pa-2"
          >
          회원가입
          </v-col>
          <v-col 
            cols="auto"
            class="pa-2"
          >
          마이페이지
          </v-col>
          <v-col 
            cols="auto"
            class="pa-2"
          >
          주문.배송
          </v-col>
          <v-col 
            cols="auto"
            class="pa-2"
          >
          장바구니
          </v-col>
        </v-row> -->

        <!-- pc / 테블릿 -->
        <!-- <v-row style="font-size: x-large; color: black;background-color: #FFCC00;" justify="center" v-if="!mobileYn">
          <v-col 
            cols="2"
            class="pa-2"
            @click="tests"
          >
          로그인
          </v-col>
          <v-col 
            cols="2"
            class="pa-2"
          >
          회원가입
          </v-col>
          <v-col 
            cols="2"
            class="pa-2"
          >
          마이페이지
          </v-col>
          <v-col 
            cols="2"
            class="pa-2"
          >
          주문.배송
          </v-col>
          <v-col 
            cols="2"
            class="pa-2"
          >
          장바구니
          </v-col>
        </v-row> -->
        <header1/>

        <!-- <v-row style="position: fixed;width: 100%;z-index: 9000;margin-top: 27px;" class="headerRow">
          <v-col cols="2" class="text-center">
            <v-icon class="ml-2 white--text" size="45" @click.stop="drawer = !drawer">mdi-menu</v-icon>
          </v-col>
          <v-col cols="8" class="text-center black--text" style="font-size: xx-large;">
          </v-col>
          <v-col cols="2" class="text-center">
            <v-icon class="mr-2 white--text" size="45">mdi-magnify</v-icon>
          </v-col>
        </v-row> -->
        <header2/>

        <!-- 모바일 -->
        <v-row v-if="mobileYn" style="margin-top: 100px;">
          <v-col cols="12">
            <v-carousel 
              style="height: 50vh;" 
              cycle 
              class="rounded-xl"
              hide-delimiter-background
              interval="2000"
              progress-color=rgba(241,211,107)
              >
              <v-carousel-item
                v-for="(img ,idx) in imgList"
                :key="`mainImg_`+idx"
                :src="img.path"
                cover
              ></v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>

        <!-- pc / 테블릿 -->
        <v-row v-if="!mobileYn" justify="center">
          <v-col cols="auto" >
            <v-carousel width="100%" height="50vh" >
              <v-carousel-item
                v-for="(img ,idx) in imgList"
                :key="`mainImg_`+idx"
                :src="img.path"
              ></v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>

        <!-- 모바일 -->
        <v-row v-if="mobileYn" justify="center" class="mb-5">
          <v-col cols="2" style="border: 3px solid pink;height: 80px;background-color: aqua;" class="pa-2 mr-4">
            신상품
          </v-col>
          <v-col cols="2" style="border: 3px solid pink;height: 80px;background-color: green;" class="pa-2 mr-4">
            베스트
          </v-col>
          <v-col cols="2" style="border: 3px solid pink;height: 80px;background-color: gray;" class="pa-2 mr-4">
            세일
          </v-col>
          <v-col cols="2" style="border: 3px solid pink;height: 80px;background-color: red;" class="pa-2">
            이벤트
          </v-col>
        </v-row>

        <v-row class="ma-0 pa-0" justify="center">
          <v-col cols="12">
            <div>
              <img :src="`./img/main/1.jpg`" style="width: 100%;height: 50vh;" class="rounded-xl"/>
            </div>
          </v-col>
        </v-row>

        <v-row class="ma-0 pa-0" justify="center">
          <v-col cols="auto">
            전서준 맹매맹
            맹
          </v-col>
        </v-row>
        
        <v-row class="mb-5 pa-0" justify="center" dense>
          <v-col cols="12">
            <swiper ref="filterSwiper" 
              :options="swiperOption" 
              role="tablist"
            >
              <swiper-slide role="tab" 
                v-for="(img ,idx) in imgList"
                :key="`swiper1_`+idx"
                class="pa-5"
              >
                <!-- <v-img :src="img.path" style="width: 60%;height: 30vh;" cover> -->
                <v-card style="width: 60%;height: 50vh;">
                  <v-img :src="img.path" style="width: 100%;height: 30vh;" @click="prodDetail"></v-img>

                  <!-- <v-card-title style="width: 100%;">
                    Top western road trips
                  </v-card-title> -->

                  <v-card-text class="wid-100">
                    {{ img.color }}<br>
                    1,000 miles of wonder
                  </v-card-text>
                  <v-card-actions>
                    <v-icon>mdi-heart</v-icon>
                  </v-card-actions>
                  
                </v-card>
              </swiper-slide>
            </swiper>
          </v-col>
        </v-row>
        
        <v-row class="mb-5 pa-0" justify="center" dense>
          <v-col cols="12">
            <swiper ref="filterSwiper" 
              :options="swiperOption" 
              role="tablist"
            >
              <swiper-slide role="tab" 
                v-for="(img ,idx) in imgList"
                :key="`swiper1_`+idx"
                class="pa-5"
              >
                <!-- <v-img :src="img.path" style="width: 60%;height: 30vh;" cover> -->
                <v-card style="width: 60%;height: 50vh;">
                  <v-img :src="img.path" style="width: 100%;height: 30vh;"></v-img>

                  <!-- <v-card-title style="width: 100%;">
                    Top western road trips
                  </v-card-title> -->

                  <v-card-text class="wid-100">
                    {{ img.color }}<br>
                    1,000 miles of wonder
                  </v-card-text>
                  <v-card-actions>
                    <v-icon>mdi-heart</v-icon>
                  </v-card-actions>
                  
                </v-card>
              </swiper-slide>
            </swiper>
          </v-col>
        </v-row>

        <v-row class="mb-5 pa-0" justify="center" dense>
          <v-col cols="12">
            <swiper ref="filterSwiper" 
              :options="swiperOption" 
              role="tablist"
            >
              <swiper-slide role="tab" 
                v-for="(img ,idx) in imgList"
                :key="`swiper1_`+idx"
                class="pa-5"
              >
                <!-- <v-img :src="img.path" style="width: 60%;height: 30vh;" cover> -->
                <v-card style="width: 60%;height: 50vh;">
                  <v-img :src="img.path" style="width: 100%;height: 30vh;"></v-img>

                  <!-- <v-card-title style="width: 100%;">
                    Top western road trips
                  </v-card-title> -->

                  <v-card-text class="wid-100">
                    {{ img.color }}<br>
                    1,000 miles of wonder
                  </v-card-text>
                  <v-card-actions>
                    <v-icon>mdi-heart</v-icon>
                  </v-card-actions>
                  
                </v-card>
              </swiper-slide>
            </swiper>
          </v-col>
        </v-row>

        <v-row class="mb-5 pa-0" justify="center" dense>
          <v-col cols="12">
            <swiper ref="filterSwiper" 
              :options="swiperOption" 
              role="tablist"
            >
              <swiper-slide role="tab" 
                v-for="(img ,idx) in imgList"
                :key="`swiper1_`+idx"
                class="pa-5"
              >
                <!-- <v-img :src="img.path" style="width: 60%;height: 30vh;" cover> -->
                <v-card style="width: 60%;height: 50vh;">
                  <v-img :src="img.path" style="width: 100%;height: 30vh;"></v-img>

                  <!-- <v-card-title style="width: 100%;">
                    Top western road trips
                  </v-card-title> -->

                  <v-card-text class="wid-100">
                    {{ img.color }}<br>
                    1,000 miles of wonder
                  </v-card-text>
                  <v-card-actions>
                    <v-icon>mdi-heart</v-icon>
                  </v-card-actions>
                  
                </v-card>
              </swiper-slide>
            </swiper>
          </v-col>
        </v-row>

        <v-row class="mb-5 pa-0" justify="center" dense>
          <v-col cols="12">
            <swiper ref="filterSwiper" 
              :options="swiperOption" 
              role="tablist"
            >
              <swiper-slide role="tab" 
                v-for="(img ,idx) in imgList"
                :key="`swiper1_`+idx"
                class="pa-5"
              >
                <!-- <v-img :src="img.path" style="width: 60%;height: 30vh;" cover> -->
                <v-card style="width: 60%;height: 50vh;">
                  <v-img :src="img.path" style="width: 100%;height: 30vh;"></v-img>

                  <!-- <v-card-title style="width: 100%;">
                    Top western road trips
                  </v-card-title> -->

                  <v-card-text class="wid-100">
                    {{ img.color }}<br>
                    1,000 miles of wonder
                  </v-card-text>
                  <v-card-actions>
                    <v-icon>mdi-heart</v-icon>
                  </v-card-actions>
                  
                </v-card>
              </swiper-slide>
            </swiper>
          </v-col>
        </v-row>

        <v-row class="mb-5 pa-0" justify="center" dense>
          <v-col cols="12">
            <swiper ref="filterSwiper" 
              :options="swiperOption" 
              role="tablist"
            >
              <swiper-slide role="tab" 
                v-for="(img ,idx) in imgList"
                :key="`swiper1_`+idx"
                class="pa-5"
              >
                <!-- <v-img :src="img.path" style="width: 60%;height: 30vh;" cover> -->
                <v-card style="width: 60%;height: 50vh;">
                  <v-img :src="img.path" style="width: 100%;height: 30vh;"></v-img>

                  <!-- <v-card-title style="width: 100%;">
                    Top western road trips
                  </v-card-title> -->

                  <v-card-text class="wid-100">
                    {{ img.color }}<br>
                    1,000 miles of wonder
                  </v-card-text>
                  <v-card-actions>
                    <v-icon>mdi-heart</v-icon>
                  </v-card-actions>
                  
                </v-card>
              </swiper-slide>
            </swiper>
          </v-col>
        </v-row>

        <!-- <v-row class="mb-5">
          <h2>상품2</h2>
          <v-carousel
            cycle
            height="200"
            hide-delimiter-background
          >
            <v-carousel-item
              v-for="(slide, i) in slides"
              :key="i"
            >
              <v-sheet
                :color="colors[i]"
                height="100%"
              >
                <div class="d-flex fill-height justify-center align-center">
                  <div class="text-h2">
                    {{ slide }} Slide
                  </div>
                </div>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
        </v-row> -->

        <!-- 상단으로 올라가는 버튼 -->
        <v-fab-transition>
          <v-btn
            v-show="btnShow"
            dark
            large
            right
            bottom
            fab
            fixed
            @click="$vuetify.goTo('.header1')"
          >
            <v-icon>mdi-chevron-double-up</v-icon>
          </v-btn>
        </v-fab-transition>

        <!-- 메뉴 drawer -->
        <!-- <v-navigation-drawer
          v-model="drawer"
          top
          temporary
          absolute
          style="z-index: 9999;width: 80%;background-color: rgba(83,106,140);"
          height="100vh"
        >
        <v-container>
          <v-row class="" style="color: rgba(196,196,196);white;height: 6vh;">
            <v-col cols="3" justify="center">
              <h4>로그인</h4>
            </v-col>
            <v-col cols="7" justify="center">
              <h4>회원가입</h4>
            </v-col>
            <v-col cols="2">
              <v-icon class="white--text"  @click="drawer = false">mdi-close</v-icon>
            </v-col>
          </v-row>
          <v-row>
            <v-col 
              cols="3"
              style="border: 1px solid black;height: 73px;background-color: white;"
              class="wid-100p d-flex justify-center align-center" 
            > 
            </v-col>
            <v-col 
              cols="3"
              style="border: 1px solid black;height: 73px;"
              class="wid-100p d-flex justify-center align-center" 
            >
            </v-col>
            <v-col 
              cols="3"
              style="border: 1px solid black;height: 73px;background-color: white;"
              class="wid-100p d-flex justify-center align-center" 
            > 
            </v-col>
            <v-col 
              cols="3"
              style="border: 1px solid black;height: 73px;"
              class="wid-100p d-flex justify-center align-center" 
            > 
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" >
              <v-card
                class="mx-auto"
                width="300"
                style="background-color: rgba(196,196,196);"
                
              >
                <v-list >
                  <v-list-group>
                    <template v-slot:activator>
                      <v-list-item-title>소율이네</v-list-item-title>
                    </template>

                    <v-list-group
                      :value="true"
                      no-action
                      sub-group
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>Admin</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item
                        v-for="([title], i) in admins"
                        :key="i"
                        link
                      >
                        <v-list-item-title v-text="title"></v-list-item-title>
                      </v-list-item>
                    </v-list-group>

                    <v-list-group
                      no-action
                      sub-group
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>Actions</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item
                        v-for="([title], i) in cruds"
                        :key="i"
                        link
                      >
                        <v-list-item-title v-text="title"></v-list-item-title>
                      </v-list-item>
                    </v-list-group>
                  </v-list-group>

                  <v-list-group>
                    <template v-slot:activator>
                      <v-list-item-title>도경이네</v-list-item-title>
                    </template>

                    <v-list-group
                      :value="true"
                      no-action
                      sub-group
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>Admin</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item
                        v-for="([title], i) in admins"
                        :key="i"
                        link
                      >
                        <v-list-item-title v-text="title"></v-list-item-title>
                      </v-list-item>
                    </v-list-group>

                    <v-list-group
                      no-action
                      sub-group
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>Actions</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item
                        v-for="([title], i) in cruds"
                        :key="i"
                        link
                      >
                        <v-list-item-title v-text="title"></v-list-item-title>
                      </v-list-item>
                    </v-list-group>
                  </v-list-group>

                  <v-list-group>
                    <template v-slot:activator>
                      <v-list-item-title>이준이네</v-list-item-title>
                    </template>

                    <v-list-group
                      :value="true"
                      no-action
                      sub-group
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>Admin</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item
                        v-for="([title], i) in admins"
                        :key="i"
                        link
                      >
                        <v-list-item-title v-text="title"></v-list-item-title>
                      </v-list-item>
                    </v-list-group>

                    <v-list-group
                      no-action
                      sub-group
                    >
                      <template v-slot:activator>
                        <v-list-item-content>
                          <v-list-item-title>Actions</v-list-item-title>
                        </v-list-item-content>
                      </template>

                      <v-list-item
                        v-for="([title], i) in cruds"
                        :key="i"
                        link
                      >
                        <v-list-item-title v-text="title"></v-list-item-title>
                      </v-list-item>
                    </v-list-group>
                  </v-list-group>

                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
          
        </v-navigation-drawer> -->
      </template>
    </v-container>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import header1 from '@/components/header/header1'
import header2 from '@/components/header/header2'

export default {
  components: {
    swiper,
    swiperSlide,
    header1,
    header2,
  },
  data() {
    return {
      imgList : [
        {path: './img/main/1.jpg', color: '남색'},
        {path: './img/main/2.jpg', color: '무지개'},
        {path: './img/main/3.jpg', color: '주황'},
        {path: './img/main/4.jpg', color: '남색'},
        {path: './img/main/5.jpg', color: '남색'},
      ],
      windowWidth: window.innerWidth,
      mobileYn: false,
      colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
        ],
      slides: [
        'First',
        'Second',
        'Third',
        'Fourth',
        'Fifth',
      ],
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: -130, // swiper-slide 사이의 간격 지정
        slidesOffsetBefore: 0, // slidesOffsetBefore는 첫번째 슬라이드의 시작점에 대한 변경할 때 사용
        slidesOffsetAfter: 0, // slidesOffsetAfter는 마지막 슬라이드 시작점 + 마지막 슬라이드 너비에 해당하는 위치의 변경이 필요할 때 사용
        freeMode: true, // freeMode를 사용시 스크롤하는 느낌으로 구현 가능
        centerInsufficientSlides: true, // 컨텐츠의 수량에 따라 중앙정렬 여부를 결정함
      },
      btnShow: false,
      location: 'bottom',
      drawer: false,
      tab: 0,
      admins: [
        ['Management', 'mdi-account-multiple-outline'],
        ['Settings', 'mdi-cog-outline'],
      ],
      cruds: [
        ['Create', 'mdi-plus-outline'],
        ['Read', 'mdi-file-outline'],
        ['Update', 'mdi-update'],
        ['Delete', 'mdi-delete'],
      ],
    }
  },
  // watch: {
  //   group () {
  //     this.drawer = false
  //   },
  // },
  created() {
    if(window.innerWidth < 767 && window.innerWidth > 350) {
      this.mobileYn = true;
    }
  },
  mounted() {
    
  },
  methods: {
    handleScroll() {
      this.btnShow = window.scrollY > 400;
    },
    menuClicked() {
      this.drawer = !this.drawer;
    },
    prodDetail() {
      this.$router.push('/productDetail');
    },//prodDetail end
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  
}
</script>

<style scoped>
.imgDf::v-deep { width:100%; height:auto; object-fit: cover; }
.swiper-slide .image-default-size {
  width:100%;
  height:100%;
  object-fit:cover;
}

</style>
<template>
  <v-container fluid :key="redrawCount">
    <template>
      <v-row class="pa-0 ma-0" align="center" style="width: 100%;">
        <v-col cols="12">
          <v-btn 
            small
            @click="addBtnClicked"
            style="float: left;"
          >추가</v-btn>

          <v-btn 
            small
            @click="saveBtnClicked"
            style="float: right;"
            color="blue lighten-2"
            class="white--text ml-2"
          >저장</v-btn>
          <!-- <v-btn 
            small
            style="float: right;"
            color="blue lighten-2"
            class="white--text"
            @click="delBtnClicked"
          >삭제</v-btn> -->
        </v-col>
        <v-col cols="12"><v-divider></v-divider></v-col>
      </v-row>
      <v-row class="pa-0 ma-0 wid-100" align="center" style="width: 100%;height:auto;font-size: small;" v-for="(item, idx) in list" :key="`list_`+idx">
        <v-col cols="12" class="pa-0 ma-0">
          <v-card style="border: 1px solid black;">
            <v-card-text class="pa-0">
              <v-row no-gutters class="d-flex flex-row-reverse">
                <!-- <v-checkbox v-model="item.select_yn"></v-checkbox> -->
                <v-icon class="pa-2" @click="iconDelClicked(item)">mdi-close</v-icon>
              </v-row>
              <v-row align="center" no-gutters class="ma-1" >
                <v-col cols="3"
                  style="border: 1px solid black;
                  height: 90px;
                  text-align: center;
                  line-height: 87px;"
                  :key="redraw"
                >
                  <label :for="`image_plus_`+idx" v-if="!item.imgPath">첨부</label>
                  <v-img :src="item.imgPath" v-if="item.imgPath" @click="imgClicked(idx)" style="height: 88px;"></v-img>
                </v-col>
                <!-- <input type="file" id="image_plus_0" style="display: none; v-model="item.file"> -->
                <v-file-input :id="`image_plus_`+idx" style="display: none;" v-model="item.file" @change="fileChanged($event,item)"></v-file-input>
                <v-col cols="9">
                  <v-row no-gutters align="center" class="ml-1">
                    <v-col cols="2">제목</v-col>
                    <v-col cols="10"><v-text-field hide-details dense outlined v-model="item.con"></v-text-field></v-col>
                  </v-row>
                  <v-row no-gutters align="center" class="ml-1">
                    <v-col cols="2">url</v-col>
                    <v-col cols="10"><v-text-field hide-details dense v-model="item.link_url" outlined></v-text-field></v-col>
                  </v-row>
                  <v-row no-gutters align="center" class="ml-1" >
                    <v-col cols="2"></v-col>
                    <v-col cols="6">
                      <v-select
                        :items="dispList"
                        item-value="val"
                        item-text="text"
                        v-model="item.disp"
                        outlined
                        hide-details
                        dense
                      ></v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-icon size="40" @click="upBtnClicked(item)" v-if="item.odr != 1">mdi-arrow-up-bold-box</v-icon>
                      <v-icon size="40" @click="downBtnClicked(item)" v-if="item.odr != list.length">mdi-arrow-down-bold-box</v-icon>
                    </v-col>
                    
                  </v-row>
                </v-col>
                <!-- <v-col cols="2"
                  style="height: 90px;"
                  class="pa-3"
                >
                제목: 
                </v-col>
                <v-col cols="7" style="height: 90px;"><v-text-field hide-details dense outlined v-model="item.con"></v-text-field></v-col> -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      <!-- <v-row class="pa-0 ma-0 wid-100" align="center" style="width: 100%;height:auto;font-size: small;" v-for="(item, idx) in list" :key="`list_`+idx">
        <v-col cols="12" class="pa-0 ma-0">
          <v-checkbox v-model="item.select_yn"></v-checkbox>
        </v-col>
        <v-col cols="4" class="pa-0 ma-0">
          노출 여부 :
        </v-col>
        <v-col cols="8" class="pa-0 ma-0">
          <v-checkbox v-model="item.disp_yn"></v-checkbox>
        </v-col>

        <v-col cols="4" class="pa-0 ma-0">
          이동 시킬 url : 
        </v-col>
        <v-col cols="8" class="pa-0 ma-0">
          <v-text-field hide-details dense v-model="item.link_url"></v-text-field>
        </v-col>

        <v-col cols="4" class="pa-0 ma-0">
          이미지 : 
        </v-col>
        <v-col cols="8" class="pa-0 ma-0">
          <v-file-input hide-details dense @change="fileChanged($event,item)" v-model="item.file"></v-file-input>
        </v-col>
        <v-col cols="12" class="pa-0 ma-0" :key="redraw">
          <v-img :src="item.imgPath" class="rounded-xl ma-3" width="40%" ></v-img>
        </v-col>

        <v-col cols="4" class="pa-0 ma-0">
          문구 : 
        </v-col>
        <v-col cols="8" class="pa-0 ma-0">
          <v-textarea hide-details dense rows="2" outlined v-model="item.con"></v-textarea>
        </v-col> -->

        
        <v-col cols="12"><v-divider></v-divider></v-col>
      </v-row>

    </template>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      fileInfo: [],
      linkList: [],
      redraw: 0,
      delList: [],
      dispList: [
        {text: '전시중지', val: 0},
        {text: '전시중', val: 1},
      ],
      redrawCount: 0,
    }
  },
  created() {
    
    this.$http.get("/admin/sub/selectTblink").then((res) => {
      console.log(res.data.linkList);
      const linkList = res.data.linkList;
      let odr = 1;
      linkList.forEach((e) => {
        // e.disp_yn = e.disp_yn == 'Y' ? true : false;
        if(e.file_path) {
          e.imgPath = e.file_path;
        }
        e.odr = odr;
        odr++;
      })
      this.linkList = linkList;
      this.list = linkList;
    }).catch(
      function (error) {
        console.log(error);
      }
    );
  },
  methods: {
    addBtnClicked() {

      //변경
      this.list.unshift({con: '', link_url: '' , imgPath: null , disp_yn: false, select_yn: false, link_sno: '',file: null, _disp_yn: '', odr: 1, disp: 0})
      let maxOdr = 1;
      this.list.forEach((e) => {
        e.odr = maxOdr;
        maxOdr++;
      });
      // console.log("this.list",this.list);
      //원본
      // let maxOdr = 1;
      // this.list.forEach((e) => {
      //   if(e.odr > maxOdr) {
      //     maxOdr = e.odr;
      //   }

      // });
      // maxOdr++;

      // this.list.push({con: '', link_url: '' , imgPath: null , disp_yn: false, select_yn: false, link_sno: '',file: null, _disp_yn: '', odr: maxOdr, disp: 0})
    },
    fileChanged(event,item) {
      console.log(event);
      console.log(item);
      if (event) {      
        var reader = new FileReader();
        reader.onload = (e) => {
          item.imgPath = e.target.result;
          this.redraw++;
        }             
        reader.readAsDataURL(event);
      } else {
        item.imgPath = null;
        this.redraw++;
      }
      
    },//fileChanged end
    saveBtnClicked() {
      if(this.list.length == 0) {
        alert('저장할 항목이 없습니다.');
        return;
      }
      const params = new FormData();
      this.list.forEach((e) => {
        // e._disp_yn = e.disp_yn ? 'Y' : 'N';
        if(e.file != null) {
          params.append('fileList', e.file);
          this.fileInfo.push(e);
        }
      })
      const noFileList = this.list.filter((e) => e.file == null);
      params.append("noFileList", JSON.stringify(noFileList));
      params.append("fileInfo", JSON.stringify(this.fileInfo));

      this.$http.post("/admin/sub/insertLink", params, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        console.log(res.data);
        if(res.data.code == 0) {
          alert('저장되었습니다.');
          this.$router.go(0);
        }
        
      }).catch(
        function (error) {
          console.log(error);
        }
      );
    },
    // delBtnClicked() {
      
    //   if(this.list.filter((e) => e.select_yn).length == 0) {
    //     alert('삭제할 항목이 없습니다.');
    //     return;
    //   }
    //   if(confirm('정말 삭제하시겠습니까?')) {
        
    //     this.delList = this.list.filter((e) => e.select_yn && e.link_sno);
    //     // console.log(this.delList);

    //     if(this.delList.length > 0) {
    //       const params = new FormData();
    //       params.append("delList", JSON.stringify(this.delList));

    //       this.$http.post("/admin/sub/deleteTblink", params).then((res) => {
    //         console.log(res.data);
    //         if(res.data.code == 0) {
    //           alert('삭제되었습니다.');
    //           this.$router.go(0);
    //         }
            
    //       }).catch(
    //         function (error) {
    //           console.log(error);
    //         }
    //       );
    //     } else {
    //       alert('삭제되었습니다.');
    //       this.$router.go(0);
    //     }
        
    //   }
    // },//delBtnClicked end
    iconDelClicked(item) {
      if(confirm('정말 삭제하시겠습니까?')) {
          const link_sno = item.link_sno;
        if(link_sno) {
          this.delList = this.list.filter((e) => e.link_sno == item.link_sno);

          const params = new FormData();
          params.append("delList", JSON.stringify(this.delList));

          this.$http.post("/admin/sub/deleteTblink", params).then((res) => {
            console.log(res.data);
            if(res.data.code == 0) {
              alert('삭제되었습니다.');
              this.$router.go(0);
            }
            
          }).catch(
            function (error) {
              console.log(error);
            }
          );
        } else {
          this.list = this.list.filter((e) => e.odr != item.odr);
        }
      }
      
      // const delList = this.list.filter((e) => e.link_sno);
    },//iconDelClicked end
    imgClicked(idx) {
      document.getElementById("image_plus_"+idx).click();
    },
    downBtnClicked(item) {
      const odr = item.odr-1;
      const odr_1 = item.odr;
      item.odr = odr_1+1;
      let ss = this.list[odr]
      this.list[odr] = this.list[odr_1];
      this.list[odr].odr = odr_1;
      this.list[odr_1] = ss;

      console.log(this.list);
      this.redrawCount++;
    },//upBtnClicked end
    upBtnClicked(item) {

      const odr = item.odr-2;
      const odr_1 = item.odr-1;
      item.odr = odr_1;
      let ss = this.list[odr]
      this.list[odr] = this.list[odr_1];
      // this.list[odr].odr = odr_1-1;
      
      this.list[odr_1] = ss;
      this.list[odr_1].odr = odr_1+1;
      

      console.log("1",this.list[odr]);
      console.log("2",this.list[odr_1]);
      this.redrawCount++;
    },//upBtnClicked end
  }
}
</script>

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router/router'
import VueTheMask from 'vue-the-mask'
import common from '@/assets/js/common.js';
import VueSession from 'vue-session';
import axios from 'axios';

var sessionOptions = {
  persist: true
}
Vue.use(VueSession, sessionOptions)

if (document.location.hostname == 'localhost') {
  axios.defaults.baseURL = 'http://localhost:443';
} else {
  axios.defaults.baseURL = document.location.origin;
}

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

Vue.use(VueTheMask);
Vue.prototype.$http = axios;
Vue.config.productionTip = false
Vue.use(common);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

<template>
    <div>
        <v-row style="font-weight: bold;font-size: medium; color: black;position: fixed;width: 100%;z-index: 9000;" justify="center" v-if="mobileYn" class="header1">
            <v-col 
              cols="auto"
              class="pa-2"
              @click="loginBtnClicked"
            >
            로그인
            </v-col>
            <v-col 
            cols="auto"
            class="pa-2"
            @click="joinClicked"
            >
            회원가입
            </v-col>
            <v-col 
            cols="auto"
            class="pa-2"
            >
            마이페이지
            </v-col>
            <v-col 
            cols="auto"
            class="pa-2"
            >
            주문.배송
            </v-col>
            <v-col 
              cols="auto"
              class="pa-2"
              @click="cartPageClicked"
            >
            장바구니
            </v-col>
        </v-row>

        <v-row style="font-size: x-large; color: black;background-color: #FFCC00;" justify="center" v-if="!mobileYn">
            <v-col
              cols="2"
              xs="2"
              class="pa-2"
              @click="loginBtnClicked"
            >
            로그인
            </v-col>
            <v-col 
            cols="2"
            xs="2"
            class="pa-2"
            >
            회원가입
            </v-col>
            <v-col 
            cols="2"
            xs="2"
            class="pa-2"
            >
            마이페이지
            </v-col>
            <v-col 
              cols="2"
              class="pa-2"
              xs="2"
            >
            주문.배송
            </v-col>
            <v-col 
              cols="2"
              class="pa-2"
              xs="2"
              @click="cartPageClicked"
            >
            장바구니
            </v-col>
        </v-row>
      </div>
</template>

<script>
export default {
  data() {
    return {
      mobileYn: false,
    }
  },
  created() {
    if(window.innerWidth < 767 && window.innerWidth > 350) {
      this.mobileYn = true;
    }
  },
  methods: {
    joinClicked() {
      this.$router.push('/join');
    },//joinClicked end 
    loginBtnClicked() {
      this.$session.set('preUrl',this.$route.path);
      this.$router.push('/login')
    },//loginBtnClicked end
    cartPageClicked() {
      //로그인 여부 체크
      this.$session.set('preUrl',this.$route.path);
      this.$router.push('/cartPage');
    },//cartPageClicked end
  }
}

</script>

<style scoped>
.header1::v-deep {
  background: url("@/img/main/header1.png");
  background-size: cover;
  border: 1px solid black;
}
</style>
<template>
  <v-container fluid class="wid-100">
    <template>
      <v-row fluid class="wid-100">
        <v-col cols="12">
            <v-btn 
              style="width: 100%;" 
              @click="saveBtnClicked"
            >
              저장
            </v-btn>
        </v-col>
        <!-- <v-col cols="6">
          <v-btn style="width: 100%;">사진 업로드</v-btn>
        </v-col> -->
      </v-row>

      <!-- 배경 -->
      <v-row align="center">
        <v-col cols="12">
          <h2>배경</h2>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="3">
          R :
        </v-col>
        <v-col cols="5">
          <v-text-field 
            hide-details 
            dense 
            outlined 
            v-model="colorMap.r_back"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="3">
          G :
        </v-col>
        <v-col cols="5">
          <v-text-field 
            hide-details 
            dense 
            outlined 
            v-model="colorMap.g_back"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="3">
          B :
        </v-col>
        <v-col cols="5">
          <v-text-field 
            hide-details 
            dense 
            outlined 
            v-model="colorMap.b_back"
          >
          </v-text-field>
        </v-col>
      </v-row>


      <!-- 카드 배경 -->
      <v-row align="center">
        <v-col cols="12">
          <h2>카드 배경</h2>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="3">
          R :
        </v-col>
        <v-col cols="5">
          <v-text-field 
            hide-details 
            dense 
            outlined 
            v-model="colorMap.r_card"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="3">
          G :
        </v-col>
        <v-col cols="5">
          <v-text-field 
            hide-details 
            dense 
            outlined 
            v-model="colorMap.g_card"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row align="center">
        <v-col cols="3">
          B :
        </v-col>
        <v-col cols="5">
          <v-text-field 
            hide-details 
            dense 
            outlined 
            v-model="colorMap.b_card"
          >
          </v-text-field>
        </v-col>
      </v-row>

    </template>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      colorMap: {
        r_back: '',
        g_back: '',
        b_back: '',
        r_card: '',
        g_card: '',
        b_card: '',
      },
    }
  },
  created() {
    
    this.$http.get("/admin/sub/getBackColor").then((res) => {
      console.log(res.data);
      this.colorMap = res.data.colorMap;
      
    }).catch(
      function (error) {
        console.log(error);
      }
    );
  },
  methods: {
    saveBtnClicked() {

      const param = new FormData();
      // param.append('r_back', this.r_back);
      // param.append('g_back', this.g_back);
      // param.append('b_back', this.b_back);

      // param.append('r_card', this.r_card);
      // param.append('g_card', this.g_card);
      // param.append('b_card', this.b_card);

      param.append('colorMap', JSON.stringify(this.colorMap));

      

      this.$http.post("/admin/sub/updateBackColor", param).then((res) => {
        console.log(res.data);
        if(res.data.code == 0) {
          alert('저장되었습니다.');
        }
        
      }).catch(
        function (error) {
          console.log(error);
        }
      );
    }
  }
}
</script>

<template>
  <v-container fluid class="wid-100" style="background-color: rgba(255,204,51); height: 100%;">
    <template >
      <v-row justify="center" align="center" >
        <v-col 
          cols="auto"
          class="pb-0 mb-0"
        >
          <img src="@/assets/logo/noname_logo.png" width="100px" justify="center" />
        </v-col>
        
        <v-col cols="12" >
          <div class="text-center text-h5">🌈키드키득 마켓 서포터즈 1기 모집🌈</div>
          <br>

          🎀모집기간 : 2024년 10월 14일 ~ 10월18일<br>
          🎀모집인원 : 5명<br>
          🎀활동기간 : 2024년 11월 ~ 2025년 3월 (5개월)<br>
          🎀활동내용 : 키드키득 제품을 착용한 홍보게시물을 인스타그램 또는 맘카페에 업로드 <br>
          🎀합격발표 : 10월 23일 (인스타그램, 개별연락)<br>
          🎀지원방법 : 프로필 링크<br>
          🎀지원자격 : 타 업체 서포터즈 활동중이 아닌 아이들 , 활발히 활동중인 계정 <br><br>

          🎈신청서 작성해주시고 해당게시물에 참여완료 댓글과 친구 2명 태그, 스토리공유 해주세요🩷<br>
          🎈홍보해주신 게시물은 키드키득마켓 인스타와 스토어에 홍보될 예정입니다🩷<br>
        </v-col>

        <!-- <v-col cols="12" >
          ☆이벤트계정,유령계정,비공개계정,외국계정은 지원불가☆
        </v-col>
        <v-col cols="12" >
          ♡남겨주신 후기를 마케팅용도, 2차활용 함에 동의하실 경우만 신청 부탁드려요♡
        </v-col> -->
        
        
      </v-row>
      <v-row justify="center" align="center">
        <div style="border: 1px solid black; width: 80%;" class="pa-2" >
          <v-col cols="12">
            ☆신청자 부분은 배송받을수 있는 정보로 입력해주세요☆<br>
            ♡남겨주신 후기를 마케팅용도, 2차활용 함에 동의하실 경우만 신청 부탁드려요♡
          </v-col>
          <v-col cols="12">
            <v-checkbox label="동의" v-model="agreeYn" ></v-checkbox>
          </v-col>
          <v-col cols="12" class="pa-0 ma-0">
            <v-text-field
              v-model="prop_name"
              placeholder="신청자 이름"
              class="text-center"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0 mb-2">
            <v-text-field
              v-model="prop_tel"
              placeholder="신청자 전화번호"
              class="text-center"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0 mb-2">
            <v-text-field
              v-model="prop_addr"
              placeholder="신청자 주소"
              class="text-center"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0 mb-2">
            <v-text-field
              v-model="inst_id"
              placeholder="활동 인스타 계정"
              class="text-center"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0 mb-2">
            <v-text-field
              v-model="child_name"
              placeholder="아이이름(두명이상이면 / 로 이어서 적어주세요)"
              class="text-center"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0 mb-2">
            <v-text-field
              v-model="child_age"
              placeholder="아이개월수"
              class="text-center"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <!-- <v-btn color="rgba(145,78,34)" class="white--text text-center" @click="btnClicked" style="width: 100%;" :disabled="!agreeYn">신청하기</v-btn> -->
            <v-btn color="rgba(145,78,34)" class="white--text text-center" @click="btnClicked" style="width: 100%;" disabled>마감</v-btn>
          </v-col>
        </div>
      </v-row>
    </template>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      agreeYn: false,
      prop_name: '',
      prop_tel: '',
      prop_addr: '',
      inst_id: '',
      child_age: '',
      child_name: '',
    }
  },
  methods: {
    btnClicked() {
      if(!this.prop_name) {
        alert('신청자 이름은 필수값 입니다.');
        return;
      } else if(!this.prop_tel) {
        alert('신청자 전화번호는 필수값 입니다.');
        return;
      } else if(!this.prop_addr) {
        alert('신청자 주소는 필수값 입니다.');
        return;
      } else if(!this.inst_id) {
        alert('인스타 계정명은 필수값 입니다.');
        return;
      } else if(!this.child_name) {
        alert('아이이름은 필수값 입니다.');
        return;
      } else if(!this.child_age) {
        alert('아이개월수는 필수값 입니다.');
        return;
      }
      const param = new FormData();
      param.append('prop_name', this.prop_name);
      param.append('prop_tel', this.prop_tel);
      param.append('prop_addr', this.prop_addr);
      param.append('inst_id', this.inst_id);
      param.append('child_name', this.child_name);
      param.append('child_age', this.child_age);

      this.$http.post("/admin/sub/insertInstaSupport", param).then((res) => {
        console.log(res.data);
        if(res.data.code == 0) {
          alert('신청 완료되었습니다.');
          this.prop_name = '';
          this.prop_tel = '';
          this.prop_addr = '';
          this.inst_id = '';
          this.child_name = '';
          this.child_age = '';
          window.location.href = "https://instagram.com/kidkidk_market?igshid=MzRlODBiNWFlZA==";
        } 
        // else {
        //   alert('이미 등록된 아이디입니다.');
        //   return;
        // }
        
      }).catch(
        function (error) {
          console.log(error);
        }
      );
    },//btnClicked end
    kidkidkGo() {
      window.location.href = 'https://kidkidk.com';
    },
    goSmartStore() {
      window.location.href = 'https://smartstore.naver.com/kidkidk-market';
    },
  }
}
</script>

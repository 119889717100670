<template>
    <v-container :style="backYn ? `background-color: rgba(`+colorMap.r_back+`,`+colorMap.g_back+`,`+colorMap.b_back+`);` : ``">
      <v-row>
        <v-col 
          cols="auto"
          xl="6"
          lg="6"
          md="2"
          sm="6"
          xs="2"
        >
            <!-- <v-avatar
            size="100px"
            color="pink lighten-2"
            style="border: 5px grey solid"
            class="float-right"
          > -->
            <img src="@/assets/kidLogo.png" class="float-right" width="150px">
            <!-- <img src="@/assets/soyul.png" alt="avatar"> -->
          <!-- </v-avatar> -->
        </v-col>
        <v-col 
          cols="auto"
          xl="6"
          lg="6"
          md="2"
          sm="6"
          xs="10"
          class="mt-10 float-left"
        >
          <h2>KIDKIDK</h2>
          <!-- <h4>@soyul</h4> -->
        </v-col>
        
      </v-row>

      <v-row justify="center" >
        <v-col 
          cols="auto"
          xl="3"
          lg="4"
          md="5"
          sm="6"
          style="font-size: 1.1em;"
        >
          📢 업무시간 : 오전 10:00 - 오후 6:00<br>
          ■ 문의 : <a href="https://talk.naver.com/ct/w4f2dd">네이버톡톡 - (Kidkidk)</a><br>
          ■ 문자,전화,메일은 확인이 어렵습니다. <br>인스타 DM 으로 문의주세요.<br>
          ■ 메일 : kidkidk-market@naver.com<br>
          <!-- <a href="https://smartstore.naver.com/kidkidk-market">스마트스토어</a><br> -->
        </v-col>
      </v-row>

      <!-- 스마트 스토어이동 -->
      <v-row justify="center" @click="imgClick('https://smartstore.naver.com/kidkidk-market')" >
        <v-col 
          cols="auto"
          xl="3"
          lg="4"
          md="7"
          sm="6"
        >
        <v-card height="auto" class="pa-2 ml-2" :style="cardYn ? `background-color: rgba(`+colorMap.r_card+`,`+colorMap.g_card+`,`+colorMap.b_card+`);width: 95%;` : `width: 95%;`">
          <v-card-text class="pa-0">
            <v-row align="center" no-gutters >
              <v-col cols="3"
                xl="3"
                lg="4"
                md="4"
                sm="4"
                
              >
                <v-img src="@/assets/naver.svg" class="rounded-xl" width="80%"></v-img>
              </v-col>
              <v-col 
                cols="8" 
                xl="8"
                lg="8"
                md="8"
                sm="8" 
                align="center"
                style="font-size: 1.2em;"
              >
                <h3>KIDKIDK</h3>
                <h3>스마트 스토어</h3>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        </v-col>
      </v-row>

      <!-- 인스타 -->
      <v-row justify="center" @click="imgClick('https://instagram.com/kidkidk_market?igshid=MzRlODBiNWFlZA==')">
        <v-col 
          cols="auto"
          xl="3"
          lg="4"
          md="7"
          sm="6"
          
        >
        <v-card height="auto" class="pa-2 ml-2" :style="cardYn ? `background-color: rgba(`+colorMap.r_card+`,`+colorMap.g_card+`,`+colorMap.b_card+`);width: 95%;` : `width: 95%;`">
          <v-card-text class="pa-0">
            <v-row align="center" no-gutters >
              <v-col cols="3"
                xl="3"
                lg="4"
                md="4"
                sm="4"
                
              >
                <v-img src="@/assets/insta.png" class="rounded-xl" width="80%"></v-img>
              </v-col>
              <v-col 
                cols="8" 
                xl="8"
                lg="8"
                md="8"
                sm="8" 
                align="center"
                style="font-size: 1.2em;"
              >
                <h3>KIDKIDK</h3>
                <h3>인스타그램</h3>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        </v-col>
      </v-row>

      <!-- <v-row justify="center" @click="imgClick('https://smartstore.naver.com/kidkidk-market/products/9386216100')">
        <v-col 
          cols="auto"
          xl="3"
          lg="4"
          md="7"
          sm="6"
          
        >
        <v-card style="width: 95%" height="auto" class="pa-2 ml-2">
          <v-card-text class="pa-0">
            <v-row align="center" no-gutters >
              <v-col cols="3"
                xl="3"
                lg="4"
                md="4"
                sm="4"
              >
                <v-img src="@/assets/할로윈.jpg" class="rounded-xl" width="80%"></v-img>
              </v-col>
              <v-col 
                cols="8" 
                xl="8"
                lg="8"
                md="8"
                sm="8" 
                align="center"
                style="font-size: 1.2em;"
              >
                <span style="font-size: medium;">할로윈 머리핀 구디백 헤어핀 머리삔 파티 토퍼</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        </v-col>
      </v-row> -->

      <!-- <v-row justify="center" @click="imgClick('https://smartstore.naver.com/kidkidk-market/products/9389140068')">
        <v-col 
          cols="auto"
          xl="3"
          lg="4"
          md="7"
          sm="6"
          
        >
        <v-card style="width: 95%" height="auto" class="pa-2 ml-2">
          <v-card-text class="pa-0">
            <v-row align="center" no-gutters >
              <v-col cols="3"
                xl="3"
                lg="4"
                md="4"
                sm="4"
              >
                <v-img src="@/assets/상품2.jpg" class="rounded-xl" width="80%"></v-img>
              </v-col>
              <v-col 
                cols="8" 
                xl="8"
                lg="8"
                md="8"
                sm="8" 
                align="center"
                style="font-size: 1.2em;"
              >
                <span style="font-size: medium;">(엄마랑 딸이랑)<br/>치크 리본 머리핀 집게핀</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        </v-col>
      </v-row> -->

      <!--(빼빼로데이)미니 케이크토퍼 브로치 머리핀 컵케이크토퍼-->
      <!-- <v-row justify="center" @click="imgClick('https://smartstore.naver.com/kidkidk-market/products/9420308657')">
        <v-col 
          cols="auto"
          xl="3"
          lg="4"
          md="7"
          sm="6"
          
        >
        <v-card style="width: 95%" height="auto" class="pa-2 ml-2">
          <v-card-text class="pa-0">
            <v-row align="center" no-gutters >
              <v-col cols="3"
                xl="3"
                lg="4"
                md="4"
                sm="4"
              >
                <v-img src="@/assets/빼빼로.jpg" class="rounded-xl" width="80%"></v-img>
              </v-col>
              <v-col 
                cols="8" 
                xl="8"
                lg="8"
                md="8"
                sm="8" 
                align="center"
                style="font-size: 1.2em;"
              >
                <span style="font-size: medium;">(빼빼로데이)<br/>미니 케이크토퍼 브로치 머리핀 컵케이크토퍼</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        </v-col>
      </v-row> -->

      <!-- (엄마랑 딸이랑)하겐 머리핀 세트 -->
      <!-- <v-row justify="center" @click="imgClick('https://smartstore.naver.com/kidkidk-market/products/9406117505')">
        <v-col 
          cols="auto"
          xl="3"
          lg="4"
          md="7"
          sm="6"
          
        >
        <v-card style="width: 95%" height="auto" class="pa-2 ml-2">
          <v-card-text class="pa-0">
            <v-row align="center" no-gutters >
              <v-col cols="3"
                xl="3"
                lg="4"
                md="4"
                sm="4"
              >
                <v-img src="@/assets/하겐 머리핀.jpg" class="rounded-xl" width="80%"></v-img>
              </v-col>
              <v-col 
                cols="8" 
                xl="8"
                lg="8"
                md="8"
                sm="8" 
                align="center"
                style="font-size: 1.2em;"
              >
                <span style="font-size: medium;">(엄마랑 딸이랑)<br/>하겐 머리핀 세트</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        </v-col>
      </v-row> -->

      <!-- 퐁퐁 똑딱핀 -->
      <!-- <v-row justify="center" @click="imgClick('https://smartstore.naver.com/kidkidk-market/products/9420647933')">
        <v-col 
          cols="auto"
          xl="3"
          lg="4"
          md="7"
          sm="6"
          
        >
        <v-card style="width: 95%" height="auto" class="pa-2 ml-2">
          <v-card-text class="pa-0">
            <v-row align="center" no-gutters >
              <v-col cols="3"
                xl="3"
                lg="4"
                md="4"
                sm="4"
              >
                <v-img src="@/assets/퐁퐁 똑딱핀.jpg" class="rounded-xl" width="80%"></v-img>
              </v-col>
              <v-col 
                cols="8" 
                xl="8"
                lg="8"
                md="8"
                sm="8" 
                align="center"
                style="font-size: 1.2em;"
              >
                <span style="font-size: medium;">퐁퐁 똑딱핀</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        </v-col>
      </v-row> -->

      <!-- for -->
      <v-row 
      justify="center" 
      v-for="(item, idx) in linkList"
      :key="`for_`+idx"
      @click="imgClick(item.link_url)">
        <v-col 
          cols="auto"
          xl="3"
          lg="4"
          md="7"
          sm="6"
        >
        <v-card height="auto" class="pa-2 ml-2" :style="cardYn ? `background-color: rgba(`+colorMap.r_card+`,`+colorMap.g_card+`,`+colorMap.b_card+`);width: 95%;` : `width: 95%;`">
          <v-card-text class="pa-0">
            <v-row align="center" no-gutters >
              <v-col cols="3"
                xl="3"
                lg="4"
                md="4"
                sm="6"
              >
                <v-img :src="item.file_path" class="rounded-xl" width="80%"></v-img>
              </v-col>
              <v-col 
                cols="8" 
                xl="8"
                lg="8"
                md="8"
                sm="6" 
                align="center"
                style="font-size: 1.2em;"
              >
                <span style="font-size: medium;">{{ item.con }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        </v-col>
      </v-row>
      
      <v-row justify="center" align="center">
        <v-col cols="auto">
          <v-avatar
            size="50px"
            class="float-right"
            @click="imgClick('https://smartstore.naver.com/kidkidk-market')"
            style="cursor: pointer;"
          >
            <img src="@/assets/naver.svg" width="150px">
          </v-avatar>
        </v-col>  
        <v-col cols="auto">
          <v-avatar
            size="50px"
            class="float-right"
            @click="imgClick('https://instagram.com/kidkidk_market?igshid=MzRlODBiNWFlZA==')"
            style="cursor: pointer;"
          >
            <img src="@/assets/insta.png" width="150px">
          </v-avatar>
        </v-col>  
      </v-row>

      <div class="mt-10"></div>

      <!-- <v-row justify="center">
        <v-col 
          cols="auto"
          xl="3"
          lg="4"
          md="5"
          sm="6"
        >
        <v-card style="width: 95%" height="auto" class="pa-1">
          <v-card-text class="pa-0">
            <v-row align="center" no-gutters>
              <v-col cols="2" >
                <v-img src="@/assets/baby_1.png" class="rounded-xl" width="100%"></v-img>
              </v-col>
              <v-col cols="9" align="center">
                서준이네<br>
                파란옷
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        </v-col>
      </v-row> -->


      <v-row justify="center">
        <v-col 
          cols="auto"
          xl="3"
          lg="4"
          md="7"
          sm="6"
          
        >
        <v-card style="width: 100%" height="auto" class="pa-1">
          <v-row  style="background-color: rgba(241,211,107);color: rgba(145,78,34);min-height: 10vh;font-size: x-small;text-align: center;" class="pa-2">
            <v-col 
              cols="12"
              class="pa-0 ma-0"
            >
              키드키득(KIDKIDK) | 대표자 : 장설
            </v-col>
            <v-col 
              cols="12"
              class="pa-0 ma-0"
            >
              사업자등록번호: 764-33-01284
            </v-col>
            <v-col 
              cols="12"
              class="pa-0 ma-0"
            >
              주소 : 경기도 김포시 사우로9번길 20-6 중앙캐슬 101동 403호
            </v-col>
            <v-col 
              cols="12"
              class="pa-0 ma-0"
            >
              TEL: 010-7577-7155
            </v-col>
            <v-col 
              cols="12"
              class="pa-0 ma-0"
            >
              키드키득(KIDKIDK) All rights reserved
            </v-col>
          </v-row>
        </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
export default {
  data() {
    return {
      linkList: [],
      colorMap: {},
      backYn: false,
      cardYn: false,
    }
  },
  created() {
    this.$http.get("/admin/sub/selectTblink").then((res) => {
      const linkList = res.data.linkList;
      this.linkList = linkList.filter((e) => e.disp == 1);
      
      const colorMap = res.data.colorMap;

      if(colorMap && colorMap.r_back && colorMap.g_back && colorMap.b_back) {
        this.backYn = true;
      }

      if(colorMap && colorMap.r_card && colorMap.g_card && colorMap.b_card) {
        this.cardYn = true;
      }

      this.colorMap = colorMap;

    }).catch(
      function (error) {
        console.log(error);
      }
    );
  },
  mounted() {
  },
  methods: {
    mainClicked() {
      this.$router.push("/");
    },
    imgClick(url) {
      window.open(url);
    },
  }
}
</script>

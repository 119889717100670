<template>
  <v-container fluid class="wid-100">
    <template>
      <v-btn color="rgba(145,78,34)" class="white--text" @click="saveBtnClicked">저장</v-btn>
      <v-row justify="center" align="center">
        <v-col cols="12" >
          <v-data-table
          :headers="headers"
          :items="list"
          :items-per-page="5"
          disable-sort
          class="elevation-1"
        >
          <template v-slot:[`item.send_yn`]="{ item }">
            <v-checkbox
              v-model="item.send_yn"
              class="pa-0 ma-0"
              hide-details
              dense
            ></v-checkbox>
          </template>
          <template v-slot:[`item.noti_yn`]="{ item }">
            <v-checkbox
              v-model="item.noti_yn"
              class="pa-0 ma-0"
              hide-details
              dense
            ></v-checkbox>
          </template>
          <template v-slot:[`item.memo`]="{ item }">
            <v-text-field 
              hide-details 
              dense 
              outlined 
              v-model="item.memo"
            >
            </v-text-field>
          </template>
        </v-data-table>
        </v-col>
      </v-row>
      <v-btn color="rgba(145,78,34)" class="white--text" @click="saveBtnClicked">저장</v-btn>
    </template>
  </v-container>
  
</template>

<script>
export default {
  data() {
    return {
      list: [],
      headers: [
        {
          text: '인스타 아이디',
          align: 'start',
          sortable: false,
          value: 'inst_id',
        },
        { text: '신청자 이름', value: 'prop_name' },
        { text: '신청자 전화번호', value: 'prop_tel' },
        { text: '신청자 주소', value: 'prop_addr' },
        { text: '아이 이름', value: 'child_name' },
        { text: '아이 나이', value: 'child_age' },
        { text: '등록일', value: 'reg_dt' },
        { text: '발송', value: 'send_yn' },
        { text: '알림', value: 'noti_yn' },
        { text: '메모', value: 'memo' },
      ],
    }
  },
  created() {
    this.getInstaList();
  },
  methods: {
    getInstaList() {
      this.$http.get("/admin/sub/getInstaList").then((res) => {
        const ll = res.data.instaList;
        ll.forEach((e) => {
          if(e.send_yn == 'Y') {
            e.send_yn = true;
          } else {
            e.send_yn = false;
          }
          if(e.noti_yn == 'Y') {
            e.noti_yn = true;
          } else {
            e.noti_yn = false;
          }
        });
        // console.log('tt',tt);
        this.list = ll;
        
      }).catch(
        function (error) {
          console.log(error);
        }
      );
    },
    saveBtnClicked() {
      const params = new FormData();
      params.append("list", JSON.stringify(this.list));
      this.$http.post("/admin/sub/updateInstaYn", params).then((res) => {
        console.log(res.data);
        alert('저장되었습니다.');
        // this.getInstaList();
      }).catch(
        function (error) {
          console.log(error);
        }
      );
    }
  }
}
</script>
<template>
  <v-container fluid class="wid-100">
    <template>
      <!-- <v-row>
        <v-col align-self="start">
          <v-icon color="rgba(145,78,34)" size="30" @click="backBtnClicked">mdi-arrow-left</v-icon>
        </v-col>
      </v-row> -->
      <v-row justify="center">
        <v-col 
          cols="6" 
          xl="2"
          lg="2"
          md="6"
          sm="6" 
        >
          <v-img src="@/assets/kidLogo.png" style="margin-bottom: -7vh;" @click="logoClicked"></v-img>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="auto">
          <h2>KIDKIDK</h2>
        </v-col>
      </v-row>

      <v-row justify="center" align="center">
        <v-card 
          class="mx-auto"
          width="300"
          style="background-color: rgba(242,211,107); color:rgba(145,78,34);"
          outlined
        >
          <v-row justify="center" align="center" class="ml-1 mr-1 mt-1 mb-0">
            <v-col cols="4">
              아이디 : 
            </v-col>
            <v-col cols="8">
              <v-text-field class="bg-white" hide-details v-model="lgin_id"></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="center" align="center" class="ml-1 mr-1 mt-0">
            <v-col cols="4">
              비밀번호 : 
            </v-col>
            <v-col cols="8" >
              <v-text-field class="bg-white" hide-details type="password" v-model="pwd"></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="center" align="center">
            <v-col cols="8" >
              <v-btn color="rgba(145,78,34)" style="width: 100%;color: white;" @click="loginBtnClicked">로그인</v-btn>
            </v-col>
          </v-row>

          <v-row justify="center" align="center" class="mb-1">
            <!-- <v-col cols="auto" >
              <span color="rgba(145,78,34)" style="font-size: small;">아이디 찾기</span>
            </v-col>
            <v-col cols="auto" >
              <span color="rgba(145,78,34)" style="font-size: small;">비밀번호 찾기</span>
            </v-col>
            <v-col cols="auto" >
              <span color="rgba(145,78,34)" style="font-size: small;">회원가입</span>
            </v-col> -->
          </v-row>

        </v-card>
      </v-row>
    </template>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      lgin_id: '',
      pwd: '',
    }
  },
  methods: {
    logoClicked() {
      this.$router.push('/');
    },//logoClicked end
    backBtnClicked() {
      const preUrl = this.$session.get('preUrl');
      if(preUrl) {
        if(preUrl != '/joinPage') {
          this.$router.push(preUrl);
        } else {
          this.$router.push('/');
        }

      } else {
        this.$router.push('/');
      }
    },//backBtnClicked end
    loginBtnClicked() {
      const params = new FormData();
      params.append("lgin_id", this.lgin_id);
      params.append("pwd", this.pwd);

      this.$http.post("/sub/login", params).then((res) => {
        console.log(res.data);
        if(res.data.code == 0) {
          this.$router.push('/admin/productJoin')
        } else {
          alert('로그인 실패');
        }

      }).catch(
        function (error) {
          console.log(error);
        }
      );
    }
  }
}
</script>